import { Link } from "react-router-dom";

export default function NotFound(result: { [key: string]: any }) {
  return (
    <div
      className="d-flex align-items-center justify-content-center text-center bg-primary"
      style={{ height: "calc(100vh - 56px)" }}
    >
      <div>
        <h1 className="display-1 fw-bold text-white">404</h1>
        <Link to="/" className="text-white">
          Return to top
        </Link>
      </div>
    </div>
  );
}
