import Loading from "../../concern/Loading";
import "./Styles.sass";
import { useEffect, useState } from "react";

export default function Main() {
  let [profile, setProfile] = useState<{ [key: string]: any }>({});
  let [saving, setSaving] = useState<boolean>(false);
  let [messages, setMessages] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchFunction = async () => {
      const resp = await (await fetch("/api/profile")).json();
      if (resp.user) setProfile(resp.user);
    };
    fetchFunction();
  }, []);

  if (Object.keys(profile).length === 0) return <Loading />;

  return (
    <main>
      {Object.keys(messages).map((type: string) => (
        <div className={`alert alert-${type}`} role="alert" key={type}>
          <div className="container d-flex justify-content-between align-items-center">
            <div>{messages[type]}</div>
            <a
              href="/"
              className={`text-${type}`}
              onClick={(e) => {
                e.preventDefault();
                const _messages = JSON.parse(JSON.stringify(messages));
                delete _messages[type];
                setMessages(_messages);
              }}
            >
              <i className="fa-solid fa-xmark" />
            </a>
          </div>
        </div>
      ))}

      <form
        className="page-container my-5"
        onSubmit={async (e: any) => {
          e.preventDefault();
          setSaving(true);

          const formData = new FormData(e.target);
          const data = Object.fromEntries(formData.entries());

          const resp = await (
            await fetch("/api/profile", {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                "Content-Type": "application/json",
              },
            })
          ).json();
          if (resp.user) setProfile(resp.user);

          const _messages = JSON.parse(JSON.stringify(messages));
          _messages.success = "保存しました。";
          setMessages(_messages);
          setSaving(false);
        }}
      >
        <div className="padding">
          <div className="container d-flex justify-content-center">
            <div className="col-xl-6 col-md-12">
              <div className="card user-card-full py-3 px-3">
                <div className="row m-l-0 m-r-0">
                  <div className="col-sm-4 bg-c-lite-green user-profile">
                    <div className="card-block text-center text-white">
                      <div className="m-b-25">
                        <img
                          src="https://img.icons8.com/bubbles/100/000000/user.png"
                          className="img-radius"
                          alt={profile.name}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="card-block">
                      <h6 className="m-b-20 p-b-5 b-b-default f-w-600">
                        Profile
                      </h6>
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fa-regular fa-user" />
                        </span>
                        <input
                          name="name"
                          defaultValue={profile.name}
                          type="text"
                          className="form-control"
                          placeholder="ニックネームを入力してください"
                          aria-label="ニックネームを入力してください"
                        />
                      </div>
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fa-regular fa-envelope" />
                        </span>
                        <input
                          name="email"
                          defaultValue={profile.email}
                          type="email"
                          className="form-control"
                          placeholder="メールアドレスを入力してください"
                          aria-label="メールアドレスを入力してください"
                          disabled
                        />
                      </div>
                      <div className="list-group-item d-flex justify-content-end">
                        {saving ? (
                          <button className="btn btn-primary" disabled>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span> 保存中</span>
                          </button>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-danger me-4"
                              onClick={async () => {
                                const resp = await (
                                  await fetch("/api/profile")
                                ).json();
                                if (resp.user) setProfile(resp.user);
                              }}
                            >
                              取り消し
                            </button>
                            <button type="submit" className="btn btn-primary">
                              保存する
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </main>
  );
}
