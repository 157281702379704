export default function Loading(oprion: { [key: string]: any }) {
  return (
    <div
      className="fa-5x text-center"
      style={{ lineHeight: oprion.height ?? "calc(100vh - 56px)" }}
    >
      <i className="fas fa-spinner fa-pulse"></i>
    </div>
  );
}
