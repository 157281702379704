import "./Styles.sass";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const random = (len: number): number => {
  return Math.floor(Math.random() * len);
};

export default function Main() {
  let [tops, setTops] = useState<{ [key: string]: any }[]>([]);
  let [features, setFeatures] = useState<{ [key: string]: any }[]>([]);

  useEffect(() => {
    const fetchFunction = async () => {
      const resp = await (await fetch(`/api/top`)).json();
      setTops(resp.tops);
      setFeatures(resp.features);
    };
    fetchFunction();
  }, []);

  return (
    <main>
      <div
        id="myCarousel"
        className="carousel slide mb-6"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          {tops.map((top, i) => (
            <button
              key={i}
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to={i}
              className={i === 0 ? " active" : ""}
            />
          ))}
        </div>
        <div className="carousel-inner">
          {tops.map((top, i) => (
            <div key={i} className={`carousel-item${i === 0 ? " active" : ""}`}>
              <div
                className="cover-image"
                style={{
                  backgroundImage: `url(${`http://img.youtube.com/vi/${top.video_url}/maxresdefault.jpg`})`,
                }}
              />
              <div className="container">
                <div
                  className={`carousel-caption ${
                    random(2) === 0
                      ? ""
                      : `text-${random(2) === 0 ? "start" : "end"}`
                  }`}
                >
                  <h1>{top.name}</h1>
                  <p className="opacity-75">
                    Some representative placeholder content for the first slide
                    of the carousel.
                  </p>
                  <p>
                    <Link
                      to={`/food/${top.id}`}
                      className="btn btn-lg btn-primary"
                    >
                      もっとみる
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">前へ</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">次へ</span>
        </button>
      </div>
      <div className="container marketing">
        <div className="row">
          {tops.map((top: any, i: number) => {
            let sum =
              top.nutrition.protein +
              top.nutrition.fat +
              top.nutrition.carbohydrate;
            return (
              <div key={i} className="col-lg-4">
                <div
                  className="cover-image rounded-circle"
                  style={{
                    backgroundImage: `url(${`http://img.youtube.com/vi/${top.video_url}/maxresdefault.jpg`})`,
                  }}
                />
                <h2 className="fw-normal">{top.name}</h2>
                <p>
                  {Math.round(top.nutrition.energy)}kcal
                  <br />
                  {Math.round((top.nutrition.protein / sum) * 100) || "?"}
                  &nbsp;:&nbsp;
                  {Math.round((top.nutrition.fat / sum) * 100) || "?"}
                  &nbsp;:&nbsp;
                  {Math.round((top.nutrition.carbohydrate / sum) * 100) || "?"}
                </p>
                <p>
                  <Link to={`/food/${top.id}`} className="btn btn-secondary">
                    もっとみる &raquo;
                  </Link>
                </p>
              </div>
            );
          })}
        </div>

        {features.map((feature, i) => {
          const img = (
            <div className="col-md-5 pt-4">
              <img
                src={`http://img.youtube.com/vi/${feature.video_url}/maxresdefault.jpg`}
                alt=""
              />
            </div>
          );
          return (
            <React.Fragment key={i}>
              <hr className="featurette-divider" />

              <div className="row featurette">
                {i % 2 === 1 ? img : <></>}
                <div className="col-md-7">
                  <h2 className="featurette-heading fw-normal lh-1">
                    {feature.name}特集
                  </h2>
                  <p className="lead">
                    いつものトーストがちょっと贅沢に！フライパンでホットサンドレシピ4選
                    <br />
                    <br />
                    <Link to="/favorite" className="btn btn-lg btn-primary">
                      もっとみる
                    </Link>
                  </p>
                </div>
                {i % 2 === 0 ? img : <></>}
              </div>
            </React.Fragment>
          );
        })}

        <hr className="featurette-divider" />
      </div>
    </main>
  );
}
