import "./Styles.sass";
import { useEffect, useState } from "react";
import { marked } from "marked";
import React from "react";
import { Link, useParams } from "react-router-dom";
import qs from "qs";
import Loading from "../../concern/Loading";
import NotFound from "../../concern/NotFound";

const formDataToQueryString = (formData: any) => {
  const params = new URLSearchParams();
  for (const [key, value] of formData) {
    params.append(key, value as string);
  }
  return params.toString();
};

export default function Main({ is_edit = false }) {
  const { id } = useParams();
  const intId = parseInt(id ?? "") ?? 0;

  let defaultServings = is_edit ? 1 : 3;

  let [checked, setChecked] = useState<number[]>([]);
  let [servings, setServings] = useState<number>(defaultServings);
  let [food, setFood] = useState<{ [key: string]: any }>({});
  let [materials, setMaterials] = useState<{ [key: string]: any }[]>([]);
  let [searchedMaterials, setSearchedMaterials] = useState<
    { [key: string]: any }[]
  >([]);
  let [videos, setVideos] = useState<{ [key: string]: any }>({});
  let [fixedAmount, setFixedAmount] = useState<boolean>(true);

  useEffect(() => {
    const fetchFunction = async () => {
      let resp: any = { food: { id: 0 }, materials: [] };
      if (id !== "0") {
        resp = await (
          await fetch(`/api/foods/${id}?e=${is_edit ? 1 : 0}`)
        ).json();
      }

      setFood(resp.food || { id: -1 });
      setMaterials(resp.materials || []);
    };
    fetchFunction();
  }, [id, is_edit]);

  if (food.id === undefined) return <Loading />;
  if ((food.id === 0 && !is_edit) || food.id === -1) return <NotFound />;

  const searchYoutube = async (form: any, pageToken: string) => {
    const formData = new FormData(form);
    const data = JSON.parse(
      JSON.stringify(Object.fromEntries(formData.entries()))
    );
    data.key = "AIzaSyCYwkukng6GwtrQ_eN2ZaxlHcewmhIIahg";
    data.type = "video";
    data.part = "snippet";
    if (pageToken) data.pageToken = pageToken;

    const query = new URLSearchParams(data);
    const resp = await (
      await fetch(`https://www.googleapis.com/youtube/v3/search?${query}`)
    ).json();
    setVideos(resp);
  };

  const contents = (
    <div className="row g-5">
      <div className="col-md-7 col-lg-7">
        <h1 className="mb-3">
          {is_edit ? (
            <input
              name="name"
              defaultValue={food.name}
              className="form-control form-control-lg"
              placeholder="料理名を入力してください"
            />
          ) : (
            food.name
          )}
        </h1>
        <div
          className="cover-image"
          style={
            food.video_url
              ? {
                  backgroundImage: `url(${`http://img.youtube.com/vi/${food.video_url}/mqdefault.jpg`})`,
                }
              : {}
          }
        >
          {!food.video_url && (
            <div className="no-image">
              <span className="fa-stack fa-4x">
                <i className="fa-regular fa-image fa-stack-1x" />
                <i className="fa-solid fa-ban fa-stack-2x text-secondary" />
              </span>
            </div>
          )}
          {is_edit && (
            <button
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasVideo"
              aria-controls="offcanvasVideo"
            >
              <i className="fa-regular fa-pen-to-square" />
              <input
                name="video_url"
                defaultValue={food.video_url}
                key={food.video_url}
              />
            </button>
          )}
        </div>
        <h4 className="mt-3 mb2">
          <span className="text-primary">作り方</span>
        </h4>
        {is_edit ? (
          <textarea
            name="recipe"
            className="form-control"
            rows={5}
            defaultValue={food.recipe}
            placeholder="作り方を入力してください"
          />
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: marked.parse(food.recipe || "") as string,
            }}
          />
        )}
      </div>
      <div className="col-md-5 col-lg-5">
        <h4
          className={`d-flex justify-content-between align-items-center ${
            is_edit ? "mb-0" : "mb-3"
          }`}
        >
          <div className="text-primary">
            <div className="d-flex align-items-center">
              <div className="input-group me-2">
                <input
                  className="form-control"
                  defaultValue={servings}
                  type="number"
                  min={1}
                  max={100}
                  onChange={(e) => {
                    if (is_edit) return;
                    const nextServings = parseInt(e.target.value);
                    console.log(servings, nextServings);
                    setServings(nextServings);
                  }}
                />
                <span className="input-group-text">人前</span>
              </div>
              <div style={{ whiteSpace: "nowrap" }}>の素材</div>
            </div>
          </div>
          <div className="badge bg-primary rounded-pill">
            {materials.length}
          </div>
        </h4>
        {is_edit && (
          <div className="form-check form-switch mb-2">
            <label>
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={fixedAmount}
                onChange={(e) => {
                  setFixedAmount(e.target.checked);
                }}
              />
              量を固定する
            </label>
          </div>
        )}
        <ul className="list-group mb-3 materials">
          {materials.length > 0 ? (
            materials.map((material: any, i: number) => {
              const gram: number = material.FoodMaterials[0].gram;
              return (
                <li
                  key={i}
                  className={`list-group-item d-flex justify-content-between align-items-center lh-sm ${
                    checked.includes(i) ? "checked" : ""
                  }`}
                >
                  <div>
                    <h6 className="my-0">
                      {material.name
                        .replace(/.+?\/ */, "")
                        .replace(/.+?\/ */, "")}
                    </h6>
                    <small className="text-body-secondary">
                      {Math.round(material.energy * gram) / 100}
                      kcal
                    </small>
                  </div>
                  {is_edit ? (
                    <div
                      className="input-group input-group-sm"
                      style={{ width: "140px" }}
                    >
                      <button
                        className="btn btn-outline-danger"
                        type="button"
                        onClick={() => {
                          const _materials = JSON.parse(
                            JSON.stringify(materials)
                          );
                          _materials.splice(i, 1);
                          setMaterials(_materials);
                        }}
                      >
                        <i className="fa-regular fa-trash-can" />
                      </button>
                      <input
                        key={`${material.FoodMaterials[0].id}-${material.FoodMaterials[0].updatedAt}`}
                        name={`food_materials[${material.id}]`}
                        type="number"
                        defaultValue={`${gram}`.replace(/[0-9]$/, "")}
                        className="form-control text-end"
                        step="0.1"
                        onChange={(e) => {
                          const _materials = JSON.parse(
                            JSON.stringify(materials)
                          );
                          _materials[i].FoodMaterials[0].gram = e.target.value;
                          setMaterials(_materials);
                        }}
                      />
                      <span className="input-group-text" id="basic-addon2">
                        g
                      </span>
                    </div>
                  ) : (
                    <span
                      className="text-body-secondary"
                      onClick={() => {
                        if (checked.includes(i)) {
                          setChecked(checked.filter((row) => row !== i));
                        } else {
                          checked.push(i);
                          setChecked(checked.map((row) => row));
                        }
                      }}
                    >
                      {Math.round(gram * servings * 10) / 10}
                      g
                      <i
                        className={`fa-regular fa-circle${
                          checked.includes(i) ? "-check" : ""
                        } fa-fw ms-2`}
                      />
                    </span>
                  )}
                </li>
              );
            })
          ) : (
            <li className="list-group-item d-flex justify-content-between align-items-center lh-sm py-3">
              <i className="text-danger">素材が登録されておりません</i>
            </li>
          )}

          {is_edit ? (
            <li className="list-group-item d-flex justify-content-end">
              <button
                className="btn btn-outline-primary btn-sm"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
              >
                素材を追加する
              </button>
            </li>
          ) : (
            <li className="list-group-item d-flex justify-content-between">
              <span>Progress</span>
              <strong>
                {Math.ceil((checked.length / materials.length) * 100)}%
              </strong>
            </li>
          )}
        </ul>
        {is_edit ? (
          <div className="list-group-item d-flex justify-content-end">
            <a
              href="/"
              className="btn btn-danger me-4"
              onClick={(e) => {
                e.preventDefault();
                window.history.back();
              }}
            >
              取り消し
            </a>
            <button type="submit" className="btn btn-primary">
              保存する
            </button>
          </div>
        ) : (
          <div className="list-group-item d-flex justify-content-between">
            <form
              method="GET"
              action="https://www.amazon.co.jp/gp/aws/cart/add.html"
              target="_blank"
            >
              <input type="hidden" name="AssociateTag" value="devenue0c-22" />
              {materials.map((material, i) => (
                <React.Fragment key={i}>
                  {(material.Amazons || []).map((amazon: any, j: number) => (
                    <React.Fragment key={j}>
                      <input
                        type="hidden"
                        name={`ASIN.${i}`}
                        value={amazon.asin}
                      />
                      <input type="hidden" name={`Quantity.${i}`} value="1" />
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
              <button type="submit" className="btn btn-warning">
                <i className="fa-brands fa-amazon me-2" />
                Amazonで素材を購入する
              </button>
            </form>
            {(food.User || {}).id === (document as any).user.id && (
              <Link to={`/food/${id}/edit`} className="btn btn-success">
                編集
              </Link>
            )}
            <form>
              <button type="submit" className="btn btn-primary">
                素材を使用する
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <main>
      {is_edit ? (
        <form
          id="food-edit"
          className="container mt-5"
          onSubmit={async (e: any) => {
            if (!is_edit) return;

            e.preventDefault();

            const formData = new FormData(e.target);
            const data = qs.parse(formDataToQueryString(formData));

            const resp = await (
              await fetch(`/api/foods/${intId}`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                  "Content-Type": "application/json",
                },
              })
            ).json();
            if (id !== `${resp.food.id}`) {
              // 新規追加時はリダイレクト
              window.location.href = `/food/${resp.food.id}/edit`;
            }
            setFood(resp.food || { id: -1 });
            setMaterials(resp.materials || []);
          }}
        >
          {contents}
        </form>
      ) : (
        <div id="food-show" className="container mt-5">
          {contents}
        </div>
      )}

      <div
        className="offcanvas offcanvas-start"
        tabIndex={-1}
        id="offcanvasVideo"
        aria-labelledby="offcanvasVideoLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasVideoLabel">
            動画を選ぶ
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <form
            onSubmit={async (e: any) => {
              e.preventDefault();
              searchYoutube(e.target, "");
            }}
          >
            <div className="input-group mb-3">
              <input
                name="q"
                type="search"
                className="form-control"
                placeholder="検索したい動画のキーワード"
                required
              />
              <button
                className="btn btn-outline-primary"
                type="submit"
                id="button-addon2"
              >
                <i className="fa-solid fa-magnifying-glass" />
              </button>
            </div>
            {videos.items && (
              <div className="list-group">
                {videos.items.map((video: { [key: string]: any }) => (
                  <button
                    key={video.id.videoId}
                    type="button"
                    className="list-group-item list-group-item-action d-flex justify-content-start align-items-start"
                    onClick={() => {
                      const _food = JSON.parse(JSON.stringify(food));
                      _food.video_url = video.id.videoId;
                      setFood(_food);
                    }}
                  >
                    <img
                      src={video.snippet.thumbnails.default.url}
                      className="me-3"
                      alt={video.snippet.title}
                    />
                    <div className="d-block">
                      <div>{video.snippet.title}</div>
                      <small className="text-secondary">
                        <i className="fa-solid fa-user me-1" />
                        {video.snippet.channelTitle}
                      </small>
                      {/* high, medium */}
                    </div>
                  </button>
                ))}
              </div>
            )}
            {videos.prevPageToken || videos.nextPageToken ? (
              <nav className="mt-3">
                <ul className="pagination pagination-sm justify-content-end">
                  <li
                    className={`page-item ${
                      videos.prevPageToken ? "" : "disabled"
                    }`}
                  >
                    <a
                      className="page-link"
                      href="/"
                      aria-label="Previous"
                      onClick={(e) => {
                        e.preventDefault();
                        searchYoutube(
                          (e.target as any).closest("form"),
                          videos.prevPageToken
                        );
                      }}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li
                    className={`page-item ${
                      videos.nextPageToken ? "" : "disabled"
                    }`}
                  >
                    <a
                      className="page-link"
                      href="/"
                      aria-label="Next"
                      onClick={(e) => {
                        e.preventDefault();
                        searchYoutube(
                          (e.target as any).closest("form"),
                          videos.nextPageToken
                        );
                      }}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-start"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            素材を追加する
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <form
            onSubmit={async (e: any) => {
              e.preventDefault();

              const formData = new FormData(e.target);
              const data = Object.fromEntries(formData.entries());
              const query = new URLSearchParams(
                JSON.parse(JSON.stringify(data))
              );

              const resp = await (
                await fetch(`/api/materials?${query}`)
              ).json();
              setSearchedMaterials(resp.materials);
            }}
          >
            <div className="input-group mb-3">
              <input
                name="keyword"
                type="search"
                className="form-control"
                placeholder="素材名を入力"
                required
              />
              <button
                className="btn btn-outline-primary"
                type="submit"
                id="button-addon2"
              >
                <i className="fa-solid fa-magnifying-glass" />
              </button>
            </div>
            <ul className="list-group">
              {searchedMaterials.map((material) => (
                <li
                  key={material.id}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span>
                    {material.name}
                    <br />
                    <small className="text-secondary">111kcal</small>
                  </span>
                  <span
                    className="badge bg-primary badge-pill"
                    onClick={() => {
                      const _materials = JSON.parse(JSON.stringify(materials));
                      material.FoodMaterials = [{ gram: "0.00" }];
                      _materials.push(material);
                      setMaterials(_materials);
                    }}
                  >
                    <i className="fa-solid fa-plus" />
                  </span>
                </li>
              ))}
            </ul>
          </form>
        </div>
      </div>
    </main>
  );
}
