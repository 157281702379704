import "./App.sass";

import { Routes, Route, BrowserRouter } from "react-router-dom";
import Header from "./concern/Header";
import Footer from "./concern/Footer";
import Loading from "./concern/Loading";
import NotFound from "./concern/NotFound";
import Guest from "./Guest/Main";
import Login from "./Login/Main";
import Dashboard from "./member/Dashboard/Main";
import Favorite from "./member/Favorite/Main";
import Pantry from "./member/Pantry/Main";
import Food from "./member/Food/Main";
import FoodIndex from "./member/Food/Index";
import FoodEdit from "./member/Food/Edit";
import Profile from "./member/Profile/Main";
import cookie from "react-cookies";
import { useEffect, useState } from "react";

export default function App() {
  const [result, setResult] = useState<{ [key: string]: any }>();

  useEffect(() => {
    const fetchFunction = async () => {
      cookie.save("origin", window.location.origin, { path: "/" });

      const user = await (await fetch("/api/login")).json();
      user.id ??= 0;
      (document as any).user = user;
      setResult(user);
    };
    fetchFunction();
  }, []);

  return (
    <BrowserRouter>
      <Header {...result} />
      {result === undefined ? (
        <Loading />
      ) : (
        <Routes>
          <Route path="/login/:provider" element={<Login />} />
          {result?.id === 0 ? (
            <>
              <Route path="/" element={<Guest />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Dashboard />} />
              <Route path="/food" element={<FoodIndex />} />
              <Route path="/food/:id/edit" element={<FoodEdit />} />
            </>
          )}
          <Route path="/food/:id" element={<Food />} />
          <Route path="/favorite" element={<Favorite />} />
          <Route path="/pantry" element={<Pantry />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
      <Footer />
    </BrowserRouter>
  );
}
