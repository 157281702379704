export default function Footer() {
  return (
    <footer className="container">
      <p className="float-end">
        <a href="/">Back to top</a>
      </p>
      <p>
        &copy; 2017–2024 Devenue, Inc.
        {" · "}
        <a href="/">Privacy</a>
        {" · "}
        <a href="/">Terms</a>
      </p>
    </footer>
  );
}
