import { Link } from "react-router-dom";
// import cookie from "react-cookies";

export default function Header(result: { [key: string]: any }) {
  return (
    <nav className="navbar navbar-expand-lg bg-dark" data-bs-theme="dark">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src="/300x300.png" alt="Gramour" />
          Gramour
        </Link>
        {Object.keys(result).length === 0 ? (
          <></>
        ) : (
          <>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarColor01"
              aria-controls="navbarColor01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarColor01">
              <ul className="navbar-nav me-auto">
                {result.id ? <></> : <></>}
              </ul>
              <ul className="navbar-nav ms-auto">
                {result.id ? (
                  <>
                    {false && (
                      <li className="nav-item">
                        <Link className="nav-link" to="/food/0/edit">
                          新しい料理
                        </Link>
                      </li>
                    )}
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        data-bs-toggle="dropdown"
                        href="/"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {result.thumb && (
                          <img
                            className="rounded-circle me-1"
                            src={result.thumb}
                            alt=""
                            style={{
                              height: "20px",
                              verticalAlign: "text-top",
                            }}
                          />
                        )}
                        {result.name || "名前未設定"}
                      </a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <Link className="dropdown-item" to="/food/0/edit">
                          新しい料理を追加する
                        </Link>
                        <Link className="dropdown-item" to="/food">
                          今まで作った料理
                        </Link>
                        <Link className="dropdown-item" to="/profile">
                          プロフィール
                        </Link>
                        <div className="dropdown-divider"></div>
                        <a
                          className="dropdown-item"
                          href="/"
                          onClick={(e) =>
                            document.cookie
                              .split(";")
                              .forEach(async function (c) {
                                await (
                                  await fetch("/api/login", {
                                    method: "DELETE",
                                  })
                                ).json();
                                // document.cookie = c
                                //   .replace(/^ +/, "")
                                //   .replace(
                                //     /=.*/,
                                //     "=;expires=" +
                                //       new Date().toUTCString() +
                                //       ";path=/"
                                //   );
                              })
                          }
                        >
                          ログアウト
                        </a>
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item">
                      <Link className="nav-link" to={result.google}>
                        Googleでログイン
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </>
        )}
      </div>
    </nav>
  );
}
