import "./Styles.sass";
import { useEffect, useState } from "react";
import Loading from "../../concern/Loading";
import moment from "moment";
import { Link } from "react-router-dom";

export default function Main({ is_edit = false }) {
  let [foods, setFoods] = useState<{ [key: string]: any }[]>([]);
  let [order, setOrder] = useState<string>("");
  let [sort, setSort] = useState<string>("");

  const fetchFunction = async (order: string, sort: string) => {
    let resp = await (
      await fetch(`/api/foods?order=${order}&sort=${sort}`)
    ).json();

    setFoods(resp.foods);
    setOrder(resp.order);
    setSort(resp.sort);
  };

  useEffect(() => {
    fetchFunction("", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (foods === undefined) return <Loading />;

  const columns: { [key: string]: string } = {
    name: "名前",
    _kcal: "カロリー",
    _protein: "タンパク質",
    _fat: "脂質",
    _carbohydrate: "炭水化物",
    createdAt: "作成日",
  };
  return (
    <main>
      <table id="food-list" className="table">
        <thead>
          <tr>
            {Object.keys(columns).map((col) => (
              <th scope="col" key={col}>
                {col.match(/^_.+/) ? (
                  <span>{columns[col]}</span>
                ) : (
                  <a
                    href="/"
                    className="d-block"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchFunction(
                        col,
                        order === col && sort === "asc" ? "desc" : "asc"
                      );
                    }}
                  >
                    <span>{columns[col]}</span>
                    <span className="fa-stack">
                      <i
                        className={`fa-solid fa-sort-up fa-stack-1x opacity-${
                          order === col && sort === "asc" ? 100 : 25
                        }`}
                      />
                      <i
                        className={`fa-solid fa-sort-down fa-stack-1x opacity-${
                          order === col && sort === "desc" ? 100 : 25
                        }`}
                      />
                    </span>
                  </a>
                )}
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {foods.map((food) => {
            return (
              <tr key={food.id}>
                <td>{food.name || "-"}</td>
                <td>{Math.round(food.nutrition.energy)}kcal</td>
                <td className="text-end">
                  {Math.round(
                    Math.round(
                      (food.nutrition.protein / food.nutrition.energy) * 100
                    )
                  ) || "-"}
                  %
                </td>
                <td className="text-end">
                  {Math.round(
                    Math.round(
                      (food.nutrition.fat / food.nutrition.energy) * 100
                    )
                  ) || "-"}
                  %
                </td>
                <td className="text-end">
                  {Math.round(
                    Math.round(
                      (food.nutrition.carbohydrate / food.nutrition.energy) *
                        100
                    )
                  ) || "-"}
                  %
                </td>
                <td>{moment(food.createdAt).format("yyyy/MM/DD HH:mm:ss")}</td>
                <td className="text-end">
                  <a
                    href="/"
                    className="text-danger me-3"
                    onClick={async (e) => {
                      e.preventDefault();
                      let resp = await (
                        await fetch(
                          `/api/foods/${food.id}?order=${order}&sort=${sort}`,
                          {
                            method: "DELETE",
                          }
                        )
                      ).json();

                      setFoods(resp.foods);
                      setOrder(resp.order);
                      setSort(resp.sort);
                    }}
                  >
                    <i className="fa-regular fa-trash-can" />
                  </a>
                  <Link
                    to={`/food/${food.id}/edit`}
                    className="fa-regular fa-pen-to-square text-success"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <ul className="list-group">
        {foods.map((food) => {
          return (
            <li
              className="list-group-item d-flex justify-content-between align-items-center"
              key={food.id}
            >
              <div>
                <div>{food.name || "-"}</div>
                <div>
                  <PFC nutrition={food.nutrition} />
                </div>
              </div>
              <div>
                <a href="/" className="text-danger me-3">
                  <i className="fa-regular fa-trash-can" />
                </a>
                <Link
                  to={`/food/${food.id}/edit`}
                  className="fa-regular fa-pen-to-square text-success"
                />
              </div>
            </li>
          );
        })}
      </ul> */}
    </main>
  );
}
