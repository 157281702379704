import React from "react";
import { Link } from "react-router-dom";

const random = (len: number): number => {
  return Math.floor(Math.random() * len);
};

const randomSrc = (): string => {
  return `https://www.kondatekun.com/DBlarge/03${`000${random(999)}`.slice(
    -3
  )}.jpg`;
};

const circleProgressBar = (numb: number) => {
  let left, right;
  if (numb < 50) {
    left = numb / 50;
    right = 0;
  } else {
    left = 1;
    right = (numb - 50) / 50;
  }

  let color = "danger";
  if (numb === 100) {
    color = "success";
  } else if (numb >= 50) {
    color = "warning";
  }

  return (
    <div className="circular">
      <div
        className="inner"
        style={{
          backgroundImage: `url(${randomSrc()})`,
        }}
      />
      <div className="outer" />
      <div className={`numb text-${color}`}>{numb}%</div>
      <div className="circle">
        <div className="bar left">
          <div
            className={`progress bg-${color}`}
            style={{ transform: `rotate(${left * 180}deg)` }}
          />
        </div>
        <div className="bar right">
          <div
            className={`progress bg-${color}`}
            style={{ transform: `rotate(${right * 180}deg)` }}
          />
        </div>
      </div>
    </div>
  );
};

export default function Main() {
  return (
    <main>
      <div className="container marketing mt-5">
        <div className="row">
          {[0, 1, 2].map((_, i) => (
            <div key={i} className="col-lg-3">
              {circleProgressBar(random(100))}
              <h2 className="fw-normal">もっちもちパン</h2>
              <p>200kcal</p>
              <p>🍖3 🧀1 🌾6</p>
              <p>
                <Link to="/food" className="btn btn-secondary">
                  作ってみる
                </Link>
              </p>
            </div>
          ))}
        </div>

        <hr className="featurette-divider" />
      </div>
    </main>
  );
}
