import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function App() {
  const { provider } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchFunction = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const data = Object.fromEntries(urlSearchParams.entries());
      await (
        await fetch(`/api/login/${provider}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
      ).json();

      setIsLoading(false);
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    };
    fetchFunction();
  }, [provider]);

  return (
    <div className="container-fluid">
      <div className="text-center my-5">
        {isLoading ? (
          "Certification in progress..."
        ) : (
          <i className="fa-regular fa-thumbs-up" />
        )}
      </div>
    </div>
  );
}
