import "./Styles.sass";
import { useState } from "react";

export default function Main() {
  let defaultServings = 3;

  let [checked, setChecked] = useState<number[]>([]);
  let [servings, setServings] = useState<number>(defaultServings);

  const materials = [
    { name: "顆粒コンソメ", gram: 10.6 },
    { name: "ウスターソース", gram: 9 },
    { name: "ケチャップ", gram: 25.9 },
    { name: "カレー粉", gram: 6 },
    { name: "ささみ", gram: 300 },
    { name: "トマト缶", gram: 388 },
    { name: "玉ねぎ", gram: 40 },
    { name: "ニンニク", gram: 5 },
    { name: "オリーブオイル", gram: 6.8 },
  ];

  return (
    <main>
      <div className="container mt-5 food">
        <div className="row g-5">
          <div className="col-md-7 col-lg-7">
            <h1 className="mb-3">低脂質トマトカレー</h1>
            <div
              className="cover-image"
              style={{
                backgroundImage: `url(${"https://mi-journey.jp/foodie/wp-content/uploads/2019/05/190534keemacurry1.jpg"})`,
              }}
            />
            <h4 className="mt-5 mb2">
              <span className="text-primary">作り方</span>
            </h4>
            <ol>
              <li>炊飯器に全部入れる</li>
              <li>炊く</li>
            </ol>
          </div>
          <div className="col-md-5 col-lg-5">
            <h4 className="d-flex justify-content-between align-items-center mb-3">
              <div className="text-primary">
                <div className="d-flex align-items-center">
                  <div className="input-group me-2">
                    <input
                      className="form-control"
                      defaultValue={servings}
                      type="number"
                      min={1}
                      max={100}
                      onChange={(e) => {
                        setServings(parseInt(e.target.value));
                      }}
                    />
                    <span className="input-group-text">人前</span>
                  </div>
                  <div style={{ whiteSpace: "nowrap" }}>の素材</div>
                </div>
              </div>
              <div className="badge bg-primary rounded-pill">
                {materials.length}
              </div>
            </h4>
            <ul className="list-group mb-3 materials">
              {materials.map((material, i) => (
                <li
                  key={i}
                  className={`list-group-item d-flex justify-content-between align-items-center lh-sm ${
                    checked.includes(i) ? "checked" : ""
                  }`}
                >
                  <div>
                    <h6 className="my-0">{material.name}</h6>
                    <small className="text-body-secondary">
                      {material.gram}
                    </small>
                  </div>
                  <span
                    className="text-body-secondary"
                    onClick={() => {
                      if (checked.includes(i)) {
                        setChecked(checked.filter((row) => row !== i));
                      } else {
                        checked.push(i);
                        setChecked(checked.map((row) => row));
                      }
                    }}
                  >
                    {Math.round(
                      ((material.gram * servings) / defaultServings) * 10
                    ) / 10}
                    g
                    <i
                      className={`fa-regular fa-circle${
                        checked.includes(i) ? "-check" : ""
                      } fa-fw ms-2`}
                    />
                  </span>
                </li>
              ))}
              <li className="list-group-item d-flex justify-content-between">
                <span>Progress</span>
                <strong>
                  {Math.ceil((checked.length / materials.length) * 100)}%
                </strong>
              </li>
            </ul>
            <form className="text-end">
              <button type="submit" className="btn btn-secondary">
                素材を使用する
              </button>
            </form>
            <form
              method="GET"
              action="https://www.amazon.com/gp/aws/cart/add.html"
            >
              <input type="hidden" name="AssociateTag" value="devenue0c-22" />
              <br />
              ASIN:
              <input type="text" name="ASIN.1" value="0747591059" />
              <br />
              Quantity:
              <input type="text" name="Quantity.1" />
              <br />
              <input type="submit" name="add" value="add" />
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}
// https://amzn.to/4bmcmea
// https://www.amazon.co.jp/%E3%83%9E%E3%82%A4%E3%83%97%E3%83%AD%E3%83%86%E3%82%A4%E3%83%B3-%E3%83%9B%E3%82%A8%E3%82%A4-%E9%BB%92%E7%B3%96%E3%83%9F%E3%83%AB%E3%82%AF%E3%83%86%E3%82%A3%E3%83%BC%E5%91%B3-MYPROTEIN-WHEY/dp/B08PNTVKKT?pd_rd_w=lgQRd&content-id=amzn1.sym.40061bc2-231b-47ad-9f3b-3423f52e8009&pf_rd_p=40061bc2-231b-47ad-9f3b-3423f52e8009&pf_rd_r=4WHWC7GFHD98Y2YH7H3A&pd_rd_wg=xtzmT&pd_rd_r=c5bf8000-f387-40cc-9836-3ece5087146a&pd_rd_i=B08PNTVKKT&th=1&linkCode=ll1&tag=devenue0c-22&linkId=177190dd0476a0bf395d281467f4b123&language=ja_JP&ref_=as_li_ss_tl
